import React, { Fragment } from 'react';
import useWindowSize from '@rehooks/window-size';
import styled from 'styled-components';

const ProjectSlide = styled.div`
  display: block;
  position: relative;
  margin: 0 5% 60px 5%;
  opacity: 0.5;
  transition: all 300ms;
  outline: none;
  transition: all 400ms ${props => props.theme.cubic};
  @media (min-width: 992px) {
      margin: 0 6.5% 80px 6.5%;
  }

  .projectslide-shadow {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80%;
    z-index: -1;
    transition: all 400ms ${props => props.theme.cubic};
  }

  .projectslide-img {
    width: 100%;
    display: block;
  }

  .projectslide-wrap {
    position: absolute;
    top: 83%;
    transform: translateY(-50%);
    left: 15px;
    transition: all 400ms ${props => props.theme.cubic};

    @media (min-width: 450px) {
      left: 20px;
    }
    @media (min-width: 768px) {
      top: 60%;
      left: 30px;
    }
    @media (min-width: 992px) {
      left: 50px;
    }
  }

  .projectslide-title {
    color: white;
    font-family: ${props => props.theme.titleFont};
    font-weight: 400;
    font-size: 2.1rem;
    line-height: 1.2;
    overflow: hidden;

    @media (min-width: 500px) {
        font-size: 3rem;
    }

    @media (min-width: 600px) {
        font-size: 3.5rem;
    }

    @media (min-width: 768px) {
        font-size: 4.3rem;
        line-height: 1.2;
    }

    @media (min-width: 992px) {
        font-size: 4rem;
        letter-spacing: 3px;
    }

    @media (min-width: 1200px) {
        font-size: 5rem;
        line-height: 1.2;
    }

    @media (min-width: 1400px) {
        font-size: 6vw;
    }
  }

  .projectslide-letter {
    position: relative;
    display: inline-block;
    overflow: hidden;

    span {
        display: inline-block;
        transform: translateX(-200px);
        transition: all 1000ms ${props => props.theme.cubic};
        transition-delay: ${props => (props.slickMoved ? '200ms' : '500ms')};
    }
  }

  .projectslide-subtitle {
    color: white;
    font-weight: 700;
    opacity: 0;
    display: block;
    transform: translateX(-30px);
    transition: all 600ms;
    transition-delay: ${props => (props.slickMoved ? '900ms' : '1250ms')};
    margin-top: 10px;
    @media (min-width: 768px) {
        font-size: 1.1rem;
        line-height: 1.2;
        margin: 0 0 0 5px;
    }
    @media (min-width: 1200px) {
        font-size: 1.4rem;
        margin-top: 0;
    }
  }

  .projectslide-spacer {
    width: 13px;
    @media (min-width: 768px) {
      width: 20px;
    }
  }
`;

const constructTitle = title => title
  .replace('\r', '')
  .replace('\n', '')
  .split('<br />')
  .map((wordBlock, wordIdx) => {
    let currentIndex = 0;
    return wordBlock.split('').map((letter, letterIdx) => {
      if (wordIdx > currentIndex) {
        currentIndex = wordIdx;
        return (
          <Fragment key={`${wordIdx}${letterIdx}`}>
            <br />
            <span className="projectslide-letter">
              <span>{letter}</span>
            </span>
          </Fragment>
        );
      }

      if (letter === ' ') {
        return (
          <span key={`${wordIdx}${letterIdx}`} className="projectslide-letter">
            <span className="projectslide-spacer" />
          </span>
        );
      }

      return (
        <span key={`${wordIdx}${letterIdx}`} className="projectslide-letter">
          <span>{letter}</span>
        </span>
      );
    });
  });

const projectSlide = ({ slickMoved, slickLoaded, slide }) => {
  const { innerWidth } = useWindowSize();

  return (
    <ProjectSlide
      slickMoved={slickMoved}
      slickLoaded={slickLoaded}
      className="projectslide __active"
    >
      <div className="projectslide-shadow" />
      {slide.node.acf.project_slider_image_mobile && innerWidth < 768 ? (
        <img
          src={slide.node.acf.project_slider_image_mobile.source_url}
          alt={slide.node.acf.project_slider_image_mobile.alt_text_text}
          className="projectslide-img"
        />
      ) : (
        <img
          src={slide.node.acf.project_slider_image.source_url}
          alt={slide.node.acf.project_slider_image.alt_text_text}
          className="projectslide-img"
        />
      )}
      <div className="projectslide-wrap">
        <h2 className="projectslide-title">
          {constructTitle(slide.node.acf.project_slider_title)}
        </h2>
        <p className="projectslide-subtitle">View project</p>
      </div>
    </ProjectSlide>
  );
};

export default projectSlide;
