import React, { Component } from 'react';
import { Link } from 'gatsby';
import Slick from 'react-slick';
import styled from 'styled-components';

import SliderControls from './sliderControls';
import ProjectSlide from "./projectSlide";
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

const Slider = styled.div`
		opacity: ${props => (props.slickLoaded ? '1' : '0')};
		transform: ${props => (props.slickLoaded ? 'translateY(0)' : 'translateY(-50px)')};
		display: block;
		width: 100%;
		position: relative;
		padding-bottom: 100px;
		transition: all 600ms;
		transition-delay: 400ms;

		.prev {
				outline: none;
				@media (min-width: 768px) {
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						width: 10%;
						z-index: 3;
				}
		}

		.next {
				outline: none;
				@media (min-width: 768px) {
						position: absolute;
						top: 0;
						right: 0;
						height: 100%;
						width: 10%;
						z-index: 3;
				}
		}

		.slick-active .slider-slide {
				.projectslide {
						&:hover,
						&:focus {
								.projectslide-shadow {
										box-shadow: none !important;
								}
						}
				}
		}

		.slider-slide {
				outline: none;
		}

		.slick-active {
				.projectslide {
						opacity: 1;

						.projectslide-shadow {
								box-shadow: 0 22px 73px 9px rgba(0, 0, 0, 0.75);
						}

						.projectslide-wrap {
								left: -10px;

								@media (min-width: 450px) {
										left: -20px;
								}
								@media (min-width: 768px) {
										left: -30px;
								}
								@media (min-width: 992px) {
										left: -50px;
								}
						}

						.projectslide-subtitle {
								opacity: ${props => (props.slickLoaded ? '1' : '0')};
								transform: ${props => (props.slickLoaded ? 'translateX(0)' : null)};
						}

						.projectslide-letter {
								span {
										transform: ${props => (props.slickLoaded ? 'translateX(0)' : null)};
								}
						}

						&:hover,
						&:focus {
								transform: translateY(10px);
								opacity: 1;
								&::before {
										box-shadow: none;
								}

								.projectslide-wrap {
										@media (min-width: 992px) {
												top: calc(60% - 20px);
										}
								}
						}
				}
		}
`;

class projectSlider extends Component {
		constructor() {
				super();
				this.state = {
					currentSlideIndex: 0,
					slickLoaded: false,
					slickMoved: false,
				}
		}


		nextSlide = () => {
				this.slider.slickNext();
		};

		previousSlide = () => {
				this.slider.slickPrev();
		};

		goToSlide = slideIndex => {
				this.slider.slickGoTo(slideIndex);
		};

		handleSlideChange = (currentSlideIndex, nextSlideIndex) => {
				this.setState({
						currentSlideIndex: nextSlideIndex,
						slickMoved: true,
				});
		};

		animateIn = () => {
				this.setState({
						slickLoaded: true,
				});
		};

		render() {
				const settings = {
						infinite: false,
						centerMode: true,
						centerPadding: '15%',
						autoplay: true,
						autoplaySpeed: 6000,
						arrows: false,
						swipe: false,
						onInit: this.animateIn,
						beforeChange: this.handleSlideChange,
						speed: 600,
						dots: false,
						ease: 'cubic-bezier(0.75, 0, 0.25, 1)',
						pauseOnHover: false,
						responsive: [
								{
										breakpoint: 992,
										settings: {
												centerPadding: '10%',
												swipe: true,
												centerMode: true,
										},
								},
								{
										breakpoint: 768,
										settings: {
												swipe: true,
												centerMode: false,
										},
								},
						],
				};

				return (
						<Slider slickLoaded={this.state.slickLoaded}>
								<button className="prev" onClick={this.previousSlide} />
								<button className="next" onClick={this.nextSlide} />

								<Slick {...settings} ref={c => (this.slider = c)}>
										{this.props.slides.map(slide => (
												<Link to={slide.node.path} key={slide.node.id} className="slider-slide">
														<ProjectSlide
																slickMoved={this.state.slickMoved}
																slickLoaded={this.state.slickLoaded}
																slide={slide}
																className="projectslide __active"
														/>
												</Link>
										))}
								</Slick>
								<SliderControls
										slides={this.props.slides}
										changeHandler={this.goToSlide}
										currentSlideIndex={this.state.currentSlideIndex}
								/>
						</Slider>
				);
		}
}
export default projectSlider;
