import React from 'react';
import styled from 'styled-components';

const Controls = styled.div`
    display: flex;
    justify-content: center;
`;

const ControlButton = styled.button`
    width: 45px;
    display: block;
    color: ${props => (props.isSlideActive ? '#ffffff' : '#4F4F4F')};
    transition: all 200ms;
    outline: none;

    @media (min-height: 900px) {
        margin: 0;
    }

    &:hover,
    &:focus {
        color: white;
    }

    &::before {
        content: '';
        display: block;
        background: ${props => (props.isSlideActive ? '#ffffff' : '#4F4F4F')};
        width: 100%;
        height: 2px;
        margin-bottom: 5px;
    }
`;

const sliderControls = ({ slides, changeHandler, currentSlideIndex }) => (
  <Controls>
    {slides && slides.map((slide, idx) => (
      <ControlButton
        key={idx}
        onClick={() => changeHandler(idx)}
        isSlideActive={currentSlideIndex === idx}
      >
        {idx < 10 ? `0${idx + 1}` : idx + 1}
      </ControlButton>
    ))}
  </Controls>
);
export default sliderControls;
