import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import ProjectSlider from '../components/homepage/projectSlider';
import ContactButtons from '../components/homepage/contactButtons';

const Homepage = styled.div`
    position: relative;
    min-height: 100vh;
    padding-top: 85px;
    display: flex;
    align-items: center;

    @media (min-width: 768px) {
        padding-top: 50px;
    }
    @media (min-width: 2500px) {
        padding: 300px 0 200px 0;
    }

    @media (min-height: 700px) {
        max-height: 100vh;
    }
    .homepage-contact {
        position: absolute;
        bottom: 0;
        left: 0;
        bottom: 50px;
        width: 100%;
        z-index: 3;
        @media (min-width: 768px) {
            padding-left: 40px;
        }
        @media (min-width: 992px) {
            bottom: 30px;
        }
        @media (min-height: 750px) {
            position: fixed;
        }
    }
`;

const IndexPage = ({ data }) => (
  <Homepage>
    <ProjectSlider slides={data.allWordpressWpProject.edges.filter(slide => slide.node.slug !== 'placeholder')} />
    <div className="homepage-contact">
      <ContactButtons options={data.allWordpressAcfOptions.edges[0].node.options} />
    </div>
  </Homepage>
);

export const query = graphql`
	query {
		allWordpressAcfOptions {
			edges {
				node {
					options {
						instagram_link
						instagram_handle
						email_address
					}
				}
			}
		}

		allWordpressWpProject(sort: { fields: menu_order, order: ASC }, filter: { id: { ne: "422" }}) {
			edges {
				node {
					id
					title
					slug
					path
					link
					acf {
						project_banner_title
						project_slider_image {
							source_url
							alt_text
						}
						project_slider_image_mobile {
							source_url
							alt_text
						}
						project_slider_title
						project_navigation_image {
							source_url
							alt_text
						}
						project_banner_image {
							source_url
							alt_text
						}
						project_content_project {
							__typename
							... on WordPressAcf_introduction_block {
								block_content
								block_side_details {
										detail_title
										detail_text
								}
							}
							... on WordPressAcf_standard_image_block {
								image {
										source_url
										alt_text
								}
							}
							... on WordPressAcf_centered_text {
								centered_text_title
								centered_text
							}
							... on WordPressAcf_full_width_image_block {
								image {
										source_url
										alt_text
								}
							}
						}
					}
			}
		}
	}
}
`;

export default IndexPage;
